import React, { useState } from 'react'
import '../css/ExpandedImages.css'

const SlideshowArrow = ({ polyStyle, style, onClick, left }) => {
    const [isMobile, setisMobile] = useState(window.innerWidth <= 820);
    let leftP = 0
    let rightP = 0

    let height = 6
    let width = 6

    if (isMobile) {
        leftP = -12
        rightP = -5
    }

    const [strokeWidth, setstrokeWidth] = useState(1.5)
    const [className, setclassName] = useState('expandedImagesArrow')
    const shake = () => {
        setclassName('expandedImagesArrow animate')
        setTimeout(() => {
            setclassName('expandedImagesArrow')
        }, 50);
    }

    const onMouseEnter = () => {
        if (isMobile) {
            return
        }
        setstrokeWidth(2.0)
    }

    const onMouseLeave = () => {
        if (isMobile) {
            return
        }
        setstrokeWidth(1.5)
    }

    const specificStyles = left
        ? { left: `${leftP}vw` }
        : { right: `${rightP}vh` };

    // const combinedStyles = { ...commonStyles, ...specificStyles };
    return (
        <svg
            className={className}
            onClick={() => { onClick(); shake() }}
            onMouseEnter={() => onMouseEnter(left)}
            onMouseLeave={onMouseLeave}
            fill="#000000"
            viewBox="0 0 24 24"
            id="left-sign"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                ...style,
                // backgroundColor: 'red',
                height: `${height}vh`, width: `${width}vw`, ...specificStyles
            }}

        >
            <polygon id="primary"
                points="14 12 19 3 10 3 5 12 10 21 19 21 14 12"
                style={{
                    ...polyStyle,
                    pointerEvents: 'visiblePainted',
                    stroke: "rgb(0, 0, 0)",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: strokeWidth
                }}>

            </polygon>
        </svg >
    )
}

export default SlideshowArrow