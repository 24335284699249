import React, { useEffect, useState } from 'react';
import '../css/reset.css';
import '../css/AlphabetsPage.css';
import Alphabet from './Alphabet';
// import SlideshowArrow from './SlideshowArrow';

function HomePage() {
  useEffect(() => {
    document.title = 'LanBar'
  }, [])

  // const isMobile = window.innerWidth <= 820;
  const [isMobile, setisMobile] = useState(window.innerWidth <= 820)

  let initial = [];
  let top = 4;
  let left = 3;
  let width = 10;
  let height = 16;
  let letterTop = 0;
  let letterLeft = 0;
  let letterHeight = '16vh';
  let letterWidth = '10vw';
  let infoButtonTop = 16.5;
  let videoTop = 36;
  let videoLeft = 3;
  let expandedImagesTop = 35;
  let expandedImagesLeft = 42;

  if (isMobile) {
    width = 15;
    letterHeight = '100%';
    letterWidth = '100%';
    height = 7;
    left = 0
    infoButtonTop = 8;
  }

  let justifyContent = 'center';
  let alignItems = 'center';
  let position = 'static';

  for (let i = 0; i < 26; i++) {
    const uppercase = String.fromCharCode(65 + i); // 'A' is 65 in ASCII
    const lowercase = String.fromCharCode(97 + i); // 'a' is 97 in ASCII
    // letters.push(uppercase + lowercase);
    // positions.push([top, left])
    let isVowel = false;
    if ('AEIOU'.includes(uppercase)) {
      isVowel = true;
    }
    initial.push({
      index: i,
      uppercase: uppercase,
      lowercase: lowercase,
      top: top,
      left: left,
      justifyContent,
      alignItems,
      position,
      containerLeft: -2,
      width: width,
      height: height,
      fontSize: 3.8,
      cursor: "pointer",
      infoDisplay: 'block',
      isExpandedView: false,
      letterPosition: 'absolute',
      letterTop: letterTop,
      letterLeft: letterLeft,
      closeButtonDisplay: 'none',
      speakerTop: 10,
      speakerLeft: 10,
      isVowel: isVowel,
      letterHeight,
      letterWidth,
      infoButtonTop,
      videoTop,
      videoLeft,
      expandedImagesTop,
      expandedImagesLeft
    })

    if (isMobile) {

      left += 17
      if (uppercase === 'E') {
        top = 15
        left = 0
      }
      if (uppercase === 'J') {
        top += 11
        left = 0
      }
      if (uppercase === 'O') {
        top += 11
        left = 0

      }
      if (uppercase === 'T') {
        top += 11
        left = 0

      }

      if (uppercase === 'Y') {
        top += 11
        left = 34.5
      }

    } else {


      // top += 20
      left += 11
      if (uppercase === 'G') {
        top = 25
        left = 3
      }
      if (uppercase === 'N') {
        top += 21
        left = 3
      }
      if (uppercase === 'U') {
        top += 21
        left = 14

      }
    }

  }

  const [alphabetStore, setalphabetStore] = useState(initial)

  const expandLetter = (letter) => {
    let temp = [...alphabetStore]

    for (const i in temp) {
      if (temp[i].uppercase !== letter) {
        if (['A', 'B', 'C', 'D', 'H', 'I', 'J', 'O', 'P', 'Q', 'R', 'V', 'W'].includes(temp[i].uppercase)) {
          temp[i].left = temp[i].left - 100
        }
        else {
          temp[i].left = temp[i].left + 100
        }
      }
      else {
        temp[i].top = 5
        temp[i].left = 3
        temp[i].position = 'absolute'
        temp[i].width = 75
        temp[i].height = 75
        temp[i].cursor = 'auto'
        temp[i].infoDisplay = 'none'
        temp[i].isExpandedView = true
        temp[i].letterPosition = 'absolute'
        temp[i].fontSize = 12

        temp[i].closeButtonDisplay = 'block'
        temp[i].letterTop = 12
        temp[i].letterLeft = 12
        temp[i].speakerTop = 10
        temp[i].speakerLeft = 45
        temp[i].letterHeight = '10vh'
        temp[i].letterWidth = '16vw'


        console.log(isMobile);
        if (isMobile) {
          console.log('ismobile');
          temp[i].letterTop = 5
          temp[i].letterLeft = 30
          temp[i].speakerTop = 15
          temp[i].speakerLeft = 28
          temp[i].videoTop = 26
          temp[i].videoLeft = 24
          temp[i].expandedImagesTop = 43
          temp[i].expandedImagesLeft = 23

        }
      }
    }

    setalphabetStore(temp)


  }

  const closeExpandedView = () => {
    setalphabetStore(initial)
  }



  return (
    <div className="alpha">
      <div className='heading'>

        <h1 style={{ fontFamily: 'LXGmodded', fontSize: "3rem", cursor: 'pointer' }}>LanBar</h1>

      </div>
      <div className='alphabetsLayout'>
        <div className='alphabetsContainer'>
          {
            alphabetStore.map((x, i) => {
              // return <Alphabet props={{ onInfoClick: handleInfoClick, alphabet: x, slideshow: slideshow }} key={`alphabet${i}`} />
              return <Alphabet
                closeButtonClick={closeExpandedView}
                infoOnClick={expandLetter}
                alphabet={x}
                key={`alphabet${i}`}
                alphabetStore={alphabetStore}
                setalphabetStore={setalphabetStore}

              />
            })
          }
        </div>

      </div>
      {/* <SlideshowArrow onClick={prevSlide} polyStyle={{ fill: 'white' }} style={{ position: 'absolute', top: '40%' }} /> */}
      {/* <SlideshowArrow onClick={nextSlide} polyStyle={{ fill: 'white' }} style={{ position: 'absolute', top: '40%', right: 0, rotate: '180deg' }} /> */}

      {/* <div className="footer" >
        <div className="grid">
          <div onClick={null} style={{ display: slideshowDisplay }} className="box">{slideshowText}</div>
          <div className="box" style={{ display: quizDisplay }}>Quiz</div>
          <div className="box" style={{ display: randomDisplay }}>Random!</div>
          <div className="box" style={{ display: unknownDisplay }}>?</div>
        </div>
      </div> */}

    </div>
  );
}

export default HomePage;
