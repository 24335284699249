import React, { useEffect, useState } from 'react'
import SlideshowArrow from './SlideshowArrow'
// import AvsAnSimple from './AvsAn'

const ExpandedImages = ({ display, alphabet, top, left }) => {
    let imgs = {
        'A': ['apple.svg', 'airplane.svg', 'ant.svg', 'angry.svg'],
        'B': ['ball.svg', 'bat.svg', 'bed.svg', 'brain.svg', 'bandage.svg'],
        'C': ['cat.svg', 'car.svg', 'cake.svg', 'chocolate.svg', 'crow.svg'],
        'D': ['dog.svg', 'doll.svg', 'duck.svg', 'doctor.svg'],
        'E': ['elephant.svg', 'egg.svg', 'ear.svg'],
        'F': ['fish.svg', 'fan.svg', 'frog.svg'],
        'G': ['goat.svg', 'grapes.svg', 'gun.svg', 'glasses.svg', 'gorilla.svg'],
        'H': ['hat.svg', 'hospital.svg', 'hand.svg', 'house.svg', 'happy.svg'],
        'I': ['ice.svg', 'igloo.svg', 'island.svg'],
        'J': ['joker.svg', 'jacket.svg', 'juice.svg'],
        'K': ['kite.svg', 'key.svg', 'king.svg'],
        'L': ['lion.svg', 'lamp.svg', 'leaf.svg'],
        'M': ['mouse.svg', 'man.svg', 'moon.svg', 'mayonnaise.svg'],
        'N': ['net.svg', 'nails.svg', 'nose.svg', 'nurse.svg', 'ninja.svg'],
        'O': ['orange.svg', 'owl.svg', 'octopus.svg'],
        'P': ['pig.svg', 'pen.svg', 'police.svg', 'pan.svg', 'pancakes.svg'],
        'Q': ['queen.svg', 'question.svg', 'quill.svg'],
        'R': ['rabbit.svg', 'ring.svg', 'rocket.svg', 'RPG.svg'],
        'S': ['sun.svg', 'sad.svg', 'snake.svg', 'syringe.svg', 'stethoscope.svg', 'signboard.svg'],
        'T': ['tennis.svg', 'tree.svg', 'tiger.svg'],
        'U': ['umbrella.svg', 'unicorn.svg', 'underwear.svg'],
        'V': ['van.svg', 'vase.svg', 'violin.svg'],
        'W': ['watermelon.svg', 'woman.svg', 'wolf.svg'],
        'X': ['box.svg', 'xylophone.svg', 'fox.svg'],
        'Y': ['yarn.svg', 'yoyo.svg', 'yolk.svg'],
        'Z': ['zebra.svg', 'zombie.svg', 'zero.svg']
    }
    const [currIndex, setcurrIndex] = useState(0)

    const prev = () => {
        if (displayAlt) {
            setdisplayAlt(false)
        }
        if (currIndex < 1) {
            setcurrIndex((imgs[alphabet].length - 1))
            return;
        }
        setcurrIndex(currIndex - 1)
    }

    const next = () => {
        if (displayAlt) {
            setdisplayAlt(false)
        }
        if (currIndex > (imgs[alphabet].length - 2)) {
            setcurrIndex(0)
            return;
        }
        setcurrIndex(currIndex + 1)
    }

    useEffect(() => {
        // console.log(imgs[alphabet]);
        imgs[alphabet].forEach(x => {
            const img = new Image();
            img.src = `images/${alphabet}/${x}.svg`
            // console.log(x);
            // console.log(`/images/${alphabet}/${x}.svg`);

        });
    });

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    let altImage = {
        'chocolate': 'gintoki.jpg',
        'dog': 'fjord.png',
        'doll': 'matryoshka.svg',
        'glasses': 'shinpachi.jpg',
        'gorilla': 'gorillas.gif',
        'crow': 'itachi.png',
        'mayonnaise': 'hijikata.jpg',
        'police': 'shinsengumi.jpg',
        'RPG': 'okita.jpg',
        'signboard': 'elizabeth.jpg',
        'umbrella': 'kagura.png',
        'ninja': 'naruto.jpg'
    }

    const [displayAlt, setdisplayAlt] = useState(false)
    const imageTitle = '2rem'

    const checkAlt = () => {
        if (displayAlt) {
            setdisplayAlt(false)
            return;
        }

        if (imgs[alphabet][currIndex].slice(0, -4) in altImage) {
            setdisplayAlt(true)
        }
    }
    return (
        <div style={{
            height: '30vh',
            width: '30vw',
            textAlign: 'center',
            position: 'absolute',
            top: `${top}vh`,
            left: `${left}vw`,
            display: display === 'block' ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <SlideshowArrow left={true} polyStyle={{ fill: 'white' }} onClick={prev} />

            <img
                alt='noalt'
                onClick={checkAlt}
                className='img' style={
                    displayAlt && (altImage[imgs[alphabet][currIndex].slice(0, -4)] === 'fjord.png' || altImage[imgs[alphabet][currIndex].slice(0, -4)] === 'itachi.png' || altImage[imgs[alphabet][currIndex].slice(0, -4)] === 'kagura.png') ? {
                        height: '35vh',
                        width: '19vw',
                    } :
                        {
                            height: '25vh',
                            width: '25vw',

                        }} src={displayAlt ? `./images/${alphabet}/${altImage[imgs[alphabet][currIndex].slice(0, -4)]}` : `./images/${alphabet}/${imgs[alphabet][currIndex]}`}></img>
            <div style={{
                position: 'absolute',
                bottom: `${top-40}vh`,

            }}>
                {/* <span style={{ color: 'black', fontWeight: 'bold', fontSize: '2vw' }}>{AvsAnSimple.query(imgs[alphabet][currIndex])}</span>  */}
                {
                    displayAlt ? <span style={{ color: 'black', fontWeight: 'bold' }}>
                        {capitalizeFirstLetter(altImage[imgs[alphabet][currIndex].slice(0, -4)].slice(0, -4))}
                    </span> :
                        imgs[alphabet][currIndex].slice(0, -4).split('').map((x, i) => {
                            return <span key={i}
                                style={x.toLowerCase() === alphabet.toLowerCase() ? { color: 'red', fontWeight: 'bold', fontSize: imageTitle } : { color: 'black', fontWeight: 'bold', fontSize: imageTitle }}>{
                                    i === 0 ? x.toUpperCase() : x
                                }</span>
                        })
                }</div>
            <SlideshowArrow left={false} polyStyle={{ fill: 'white' }} style={{ rotate: '180deg' }} onClick={next} />
        </div >
    )
}

export default ExpandedImages